<template>
  <b-card>
    <b-form>
      <b-form-group label="Tên chương trình tiêu điểm">
        <b-form-input
          ref="name"
          v-model="form.name"
          placeholder="Nhập tên chương trình"
          required
          autofocus
        ></b-form-input>
      </b-form-group>
      <b-form-group label="Thời gian áp dụng chương trình">
        <b-form-checkbox
          v-model="form.is_specifically_time"
          :value="true"
          :unchecked-value="false"
          >Chọn thời hạn cụ thể</b-form-checkbox
        >
        <b-form-group class="mt-1">
          <div class="flex items-center gap-3">
            <b-form-datepicker
              ref="start_date"
              placeholder="Chọn ngày bắt đầu"
              v-model="form.from_date"
              :locale="$i18n.locale"
              v-bind="$getCalendarLabel($i18n.locale)"
            ></b-form-datepicker>
            <b-form-select
              ref="select_duaration"
              v-model="form.duration"
              :options="durationOptions"
              v-if="!form.is_specifically_time"
            ></b-form-select>
            <b-form-datepicker
              v-else
              placeholder="Chọn ngày kết thúc"
              ref="to_date"
              v-model="form.to_date"
              :locale="$i18n.locale"
              v-bind="$getCalendarLabel($i18n.locale)"
            ></b-form-datepicker>
          </div>
        </b-form-group>
      </b-form-group>
      <!-- <b-form-group label="Số tiền nạp">
        <div class="flex items-center gap-3">
          <b-input-group required>
            <template #append>
              <b-input-group-text>VND</b-input-group-text>
            </template>
            <b-form-input v-model="form.moneyValue" @input="handleChangeMoneyValue"></b-form-input>
          </b-input-group>
          <img src="/icons/icon-equivalent.svg" alt />
          <b-input-group required>
            <template #append>
              <b-input-group-text>Điểm</b-input-group-text>
            </template>
            <b-form-input v-model="pointsValue.round" :disabled="true"></b-form-input>
          </b-input-group>
        </div>
      </b-form-group>-->
      <label style="font-size: 14px">Gói thành viên</label>
      <el-select
        v-model="form.member"
        multiple
        placeholder="Chọn HODO Membership"
        class="w-full mb-2"
      >
        <el-option
          v-for="item in HODO_MEMBERSHIP"
          :key="item.value"
          :label="item.text"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <!-- <b-form-group label="Gói thành viên" class="w-100">
        <b-form-select
          v-model="form.member"
          :options="HODO_MEMBERSHIP"
        ></b-form-select>
      </b-form-group> -->

      <div>
        <b-form-group
          label="Sản phẩm, dịch vụ được áp dụng"
          v-slot="{ ariaDescribedby }"
        >
          <b-form-radio
            v-model="form.is_all_products"
            :aria-describedby="ariaDescribedby"
            :value="1"
            >Áp dụng cho tất cả sản phẩm, dịch vụ</b-form-radio
          >
          <b-form-radio
            v-model="form.is_all_products"
            :aria-describedby="ariaDescribedby"
            class="mt-1"
            :value="2"
            >Áp dụng cho sản phẩm, dịch vụ cụ thể</b-form-radio
          >
        </b-form-group>
        <div v-if="form.is_all_products === 2">
          <div class="flex justify-content-end">
            <b-button
              variant="primary"
              class="w-fit"
              @click="handleChooseProduct"
              >Chọn sản phẩm</b-button
            >
          </div>
          <b-table
            striped
            hover
            :items="dataTable"
            :fields="fieldsTable"
            class="mt-2 mb-3"
          >
            <template #cell(index)="data">{{ data.index + 1 }}</template>
          </b-table>
        </div>
      </div>
    </b-form>
    <div class="flex justify-content-end gap-3 mt-2">
      <b-button
        variant="outline-primary"
        @click="$router.push({ name: 'spend' })"
        >Huỷ</b-button
      >
      <b-button variant="primary" class="w-fit" @click="handleSave"
        >Lưu</b-button
      >
    </div>
    <ModalListMkProducts
      ref="ModalListMkProducts"
      @onChangeSelectedProducts="handleChangeSelectedProducts"
      @onSelectedAll="handleSelectedAllProducts"
    />
  </b-card>
</template>
<script>
import appUtils from "@/utils/appUtils";
import ModalListMkProducts from "../ModalListMkProducts.vue";
import {
  EXPIRED_RULE_CREDIT,
  HODO_CREDIT_TYPE,
  HODO_MEMBERSHIP,
} from "@/utils/constant";
import { omit } from "lodash";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import { Select, Option } from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

export default {
  name: "CreateSpendPointTransaction",
  components: { ModalListMkProducts, "el-select": Select, "el-option": Option },
  data() {
    return {
      form: {
        name: "",
        duration: 1,
        is_specifically_time: false,
        moneyValue: "",
        credit: 1,
        is_programme: false,
        total_point: "",
        member: [],
        is_all_products: 2,
        from_date: "",
        to_date: "",
      },
      durationOptions: [
        { value: 1, text: "6 tháng kể từ ngày bắt đầu", month: 6 },
        { value: 2, text: "12 tháng kể từ ngày bắt đầu", month: 12 },
        { value: 3, text: "24 tháng kể từ ngày bắt đầu", month: 24 },
      ],
      fieldsTable: [
        {
          key: "index",
          label: "STT",
        },
        {
          key: "name",
          label: "Tên sản phẩm",
        },
        {
          key: "sku",
          label: "Mã đăng ký",
        },
        {
          key: "supplier.name",
          label: "Nhà cung cấp",
        },
      ],
      dataTable: [],
      configData: {},
      realData: {
        moneyValue: null,
      },
      HODO_MEMBERSHIP,
    };
  },
  computed: {
    pointsValue() {
      const points = this.realData.moneyValue / (this.configData?.rate || 0);

      return {
        round: this.roundToFourDecimals(points),
        value: points,
      };
    },
  },
  created() {
    this.getExchangeRateByOrgId();
  },
  methods: {
    handleChooseProduct() {
      this.$refs.ModalListMkProducts.handleOpenModal(true);
    },
    handleChangeSelectedProducts(data) {
      this.dataTable = data;
    },
    handleSelectedAllProducts(data) {
      this.dataTable = data;
    },
    roundToFourDecimals(number) {
      if (!number) return "";
      const isInteger = Number.isInteger(number);

      let result;
      if (isInteger) {
        result = appUtils.numberFormat(number);
      } else {
        const formatNumber = parseFloat(number).toFixed(4);
        const splitNumber = formatNumber.split(".");
        const intNumber = appUtils.numberFormat(splitNumber[0]);
        result = `${intNumber}.${splitNumber[1]}`;
      }

      return result;
    },
    async getExchangeRateByOrgId() {
      try {
        const userData = appUtils.getLocalUser();

        if (!userData.supplierInfo?.org_id) return;
        const org_id = userData.supplierInfo?.org_id;

        const response = await this.$rf
          .getRequest("CommonRequest")
          .getExchangeRateDetail(org_id);

        this.configData = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    handleChangeMoneyValue(value) {
      const newValue = Number(value?.toString()?.replaceAll(",", ""));
      this.form.moneyValue = appUtils.numberFormat(newValue);

      this.realData.moneyValue = newValue;

      this.form.memberPoints = this.roundToFourDecimals(
        newValue / this.configData.rate
      );

      this.realData.memberPoints = newValue / this.configData.rate;
    },
    async handleSave() {
      try {
        if (!this.form.name) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: this.$t("Bạn cần điền tên chương trình tiêu điểm"),
              icon: "AlertTriangleIcon",
              variant: "warning",
            },
          });
          this.$refs.name.focus();
          return;
        }
        if (
          !this.form.from_date ||
          (this.form.is_specifically_time && !this.form.to_date) ||
          (!this.form.is_specifically_time && !this.form.duration)
        ) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: this.$t("Bạn cần chọn thời gian áp dụng chương trình"),
              icon: "AlertTriangleIcon",
              variant: "warning",
            },
          });
          if (!this.form.from_date) this.$refs.start_date.focus();
          if (this.form.is_specifically_time && !this.form.to_date)
            this.$refs.to_date.focus();
          if (!this.form.is_specifically_time && !this.form.select_duaration)
            this.$refs.select_duaration.focus();
          return;
        }

        if (!this.form.member?.length) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: this.$t("Bạn cần chọn gói thành viên"),
              icon: "AlertTriangleIcon",
              variant: "warning",
            },
          });

          return;
        }

        const userData = appUtils.getLocalUser();

        if (!userData.supplierInfo?.org_id) return;

        // const isValid = this.handleCheckValid()
        // if (!isValid) return

        const params = {
          expired_rule: this.form.is_specifically_time
            ? EXPIRED_RULE_CREDIT.prefixReset
            : EXPIRED_RULE_CREDIT.loopReset,
          // money_amount: this.realData.moneyValue,
          // point: this.pointsValue.value,
          exchange_rate: this.configData.rate,
          org_id: userData.supplierInfo?.org_id,
          name: this.form.name,
          type: HODO_CREDIT_TYPE.redemption,
          start_date: this.form.from_date
            ? window.moment(this.form.from_date).valueOf()
            : undefined,
          expired_date: this.handleGetExpiredDate(),
          product_partner_program_ids: this.dataTable?.map((elm) => elm.id),
          membership_program_ids: this.form.member,
        };
        const response = await this.$rf
          .getRequest("CommonRequest")
          .createPointProgram(params);

        if (response.status === 200) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: this.$t("Tạo chương trình tiêu điểm thành công"),
              icon: "CheckIcon",
              variant: "success",
            },
          });

          this.$router.push({ name: "spend" });
        }
      } catch (error) {
        console.log(error);
      }
    },
    handleGetExpiredDate() {
      if (this.form.is_specifically_time) {
        return this.form.to_date
          ? window.moment(this.form.to_date).valueOf()
          : undefined;
      } else {
        const month =
          this.durationOptions.find((item) => item.value === this.form.duration)
            ?.month || 0;

        return window.moment(this.form.from_date).add(month, "month").valueOf();
      }
    },
    handleCheckValid() {
      const requiredForm = omit(this.form, [
        "to_date",
        "duration",
        "is_specifically_time",
      ]);

      const isValidForm = Object.values(requiredForm).every((item) => item);

      const isValidDuration = Boolean(
        this.form.is_specifically_time === EXPIRED_RULE_CREDIT.prefixReset
          ? this.form.to_date
          : this.form.duration
      );

      if (!isValidForm || !isValidDuration) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t("Vui lòng nhập đầy đủ dữ liệu"),
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });

        return false;
      }

      return true;
    },
  },
};
</script>
<style lang="scss" scoped>
.container-credit {
  background: #fff;
}
</style>